<script>
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	lang: 'shop',
	mixins: [hooksMixin('ProductPage')],
	inject: ['hookContainer'],
	props: {
		sheetType: String,
	},
	data() {
		return {
			showPromotions: false,
		}
	},
	computed: {
		ProductPage() {
			return this.hookContainer()
		},
		product() {
			return this.ProductPage.product
		},
		reviewsConfig() {
			return this.ProductPage.reviewsConfig
		},
		customization() {
			return this.ProductPage.customization
		},
		validation() {
			return this.ProductPage.validation
		},
		hideStockAvailabilityMessage() {
			return this.ProductPage.hideStockAvailabilityMessage
		},
		colSizes() {
			return this.ProductPage.colSizes
		},
		marketplaceMode() {
			return process.env.VUE_APP_MARKETPLACE_MODE
		},
		showSeller() {
			let sellerId = this.$srv('sellerInfo.sellerId', [])
			return sellerId.some((id) => id == this.product.seller.id)
		},
		selectedVariant() {
			return this.ProductPage.selectedVariant
		},
		buttonText() {
			return this.ProductPage.buttonText
		},
		discountsTagsTexts() {
			if (!this.ProductPage.discountsTagsTexts?.length) return
			return this.ProductPage.discountsTagsTexts.map(this.splitTagText)
		},
		hasLimitedTimeOffer() {
			return this.ProductPage.hasLimitedTimeOffer
		},
		productOffers() {
			return this.ProductPage.productOffers
		},
		financingTagHtml() {
			return this.ProductPage.financingTagHtml
		},
		selectedVariantId: {
			get() {
				return this.ProductPage.selectedVariantId
			},
			set(val) {
				this.$emit('update:selectedVariantId', val)
			},
		},
		imgIndex: {
			get() {
				return this.ProductPage.imgIndex
			},
			set(val) {
				this.$emit('update:imgIndex', val)
			},
		},
		cRatingAvg: {
			get() {
				return this.ProductPage.product.ratingAvg
			},
			set(val) {
				this.$emit('update:ratingAvg', val)
			},
		},
		cImgIndex: {
			get() {
				return this.imgIndex
			},
			set(val) {
				this.$emit('update:imgIndex', val)
			},
		},
	},
	methods: {
		scrollToReviews() {
			this.$vuetify.goTo('#reviews-scroll-point', { duration: 500, offset: 0 })
		},
		splitTagText(tag) {
			let text = tag.split('$').map((s) => s.trim())
			let msg = text[0]
			let amount = text[1]
			return { msg, amount }
		},
	},
}
</script>

<template>
	<div class="pb-3">
		<v-row :class="sheetType == 'defaultInverted' && 'flex-row-reverse'">
			<v-col cols="12" :md="colSizes.left" class="no-mobile-px" :class="$b({ d: 'grey-border-right' })">
				<hook zone="images">
					<ProductImagesCarousel
						v-if="sheetType == 'default' || sheetType == 'defaultInverted' || $b.m"
						v-model="cImgIndex"
						:scale-down="false"
						:product="product"
						:invert="sheetType == 'defaultInverted'"
					/>
					<ProductImagesGrid
						v-else-if="sheetType == 'grid'"
						v-model="cImgIndex"
						:scale-down="false"
						:product="product"
					/>
				</hook>
			</v-col>
			<v-col cols="12" :md="colSizes.right">
				<div class="px-0 px-md-6" v-super-sticky="$b.m ? false : { topAsHeightOf: '.appbar' }">
					<v-row>
						<v-col cols="10">
							<h1 class="header font-weight-bold font-6 py-1">{{ product.name }}</h1>
							<!-- USER REVIEWS -->
							<hook
								zone="user-reviews"
								v-if="
									reviewsConfig &&
									reviewsConfig.enabled &&
									(reviewsConfig.permissions == 'free' ? true : product.ratingCount > 0)
								"
							>
								<div class="mb-2 user-reviews-btn d-flex align-center" @click="scrollToReviews">
									<v-rating
										dense
										half-increments
										v-model="cRatingAvg"
										:color="$vars.strongtext"
										background-color="#cececd"
										small
										readonly
									></v-rating>
									<p class="mb-0 ml-1 d-inline-block link--text">({{ product.ratingCount }})</p>
								</div>
							</hook>
							<!-- END USER VALORATIONS -->
							<hook zone="brand-sku">
								<div class="d-flex align-center">
									<div v-if="product.brand" class="mr-2">
										<SafeLink :to="$shop.getShopRoute({ brand: product.brand.urlName })">
											Marca: <span class="font-weight-bold">{{ product.brand.name }}</span>
										</SafeLink>
									</div>
									<div>
										Sku: <span class="font-weight-bold">{{ selectedVariant.sku }}</span>
									</div>
								</div>
								<div class="my-2" v-if="marketplaceMode && showSeller">
									<div v-if="product.seller.logo" style="height: 20px" class="mt-2 mb-1">
										<Media
											:src="product.seller.logo"
											height="20px"
											max-width="130px"
											position="left"
										/>
									</div>
									<div>
										Producto vendido por
										<span class="font-weight-bold">{{ product.seller.name.toUpperCase() }}</span>
									</div>
								</div>
							</hook>
						</v-col>
						<v-col cols="2" class="text-right">
							<div
								class="wishlist-share w100 h100 d-flex flex-column align-center justify-center"
								:class="{ 'align-end': $b.t }"
							>
								<ProductWishlistToggler
									class="flex-grow mb-3"
									:productId="product.id"
									:heartWidth="25"
								/>
								<ShareProduct :productName="product.name" />
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="d-flex flex-wrap align-center icons-offer">
							<CollectionEventIcon
								multiple
								:product="product"
								class="event-icon"
								:limitAmount="$b.m ? 5 : null"
							/>
							<ProductPage-LimitedTimeOffer
								:product="product"
								:productOffers="productOffers"
								v-if="hasLimitedTimeOffer"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div style="position: relative">
								<VariantsSelector
									v-model="selectedVariantId"
									:product="product"
									class="pb-3"
									:show-select-input="true"
								/>
								<hook zone="price-layout">
									<PriceLayout
										:product="product"
										:prev-price="selectedVariant.pvPrice.prevPrice"
										:price="selectedVariant.pvPrice.price"
										:discount-pct="selectedVariant.pvPrice.discountPct"
										:unit-metric="product.packMetric ? product.unitMetric : null"
										:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
										price-class="font-8"
									/>
								</hook>
								<div class="d-inline-block promotions link--text my-4" @click="showPromotions = true">
									Mirá todas las promociones
								</div>
								<ProductPage-PaymentsModal
									v-model="showPromotions"
									:selectedVariantId="selectedVariant.id"
								/>
								<div
									class="my-2 py-1 px-2 primary rounded"
									style="width: fit-content"
									v-if="financingTagHtml"
									v-html="financingTagHtml"
								></div>
								<div
									class="my-2 py-1 px-2 secondary rounded"
									style="width: fit-content"
									v-for="(item, i) of discountsTagsTexts"
									:key="i"
								>
									{{ item.msg }} <span class="discount-tag-price">${{ item.amount }}</span>
								</div>

								<div class="py-2 font-1">
									<ProductStockAvailabilityMessage
										v-if="!hideStockAvailabilityMessage"
										:stock="selectedVariant.stock"
										:product="product"
										class="py-1"
									/>
									<StockReminderForm :product="product" :variant="selectedVariant" />
									<div class="d-flex align-center py-1" v-if="selectedVariant.type == 'digital'">
										<v-icon class="mr-2">mdi-cloud-download</v-icon>
										{{ 'Producto digital descargable' | lang }}
									</div>
								</div>
								<hook zone="actions">
									<ProductActions
										:product="product"
										:variant="selectedVariant"
										:customization="customization"
										:ctaText="buttonText"
										@validation="ProductPage.setValidation($event)"
										class="py-3"
									/>
								</hook>
								<div class="my-1 pt-2 mx-n3 px-3 mx-md-n9 px-md-9" style="border-top: 1px solid #eee">
									<ProductPage-DeliveryCalc
										:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
										:selectedVariantId="selectedVariantId"
									/>
								</div>
								<div>
									<ProductPage-AdditionalPaymentInfo :product="product" />
								</div>
								<div
									class="pa-4 grey lighten-4 rounded-lg"
									v-if="$srv('orderTotalGoalProgressConfig.showInProductPage')"
								>
									<OrderTotalGoalProgress use-image />
								</div>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<style>
.shadow {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.sticky-right-col {
	position: sticky;
	bottom: 16px;
	align-self: flex-end;
}

.promotions {
	text-decoration: underline;

	&:hover {
		cursor: pointer;
	}
}

::v-deep iframe {
	max-width: 100%;
	widows: 500px;
	min-height: 250px;
}

.wishlist-share {
	max-height: 102px;
	max-width: 102px;
}

.icons-offer {
	padding-top: 0;
	padding-bottom: 0;
	gap: 6px;
}

.user-reviews-btn {
	width: max-content;
	cursor: pointer;
}
</style>
